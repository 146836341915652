var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAlert),expression:"showAlert"}],staticClass:"v-card-border-none",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-2 pb-2 v-card-custom-title"},[_c('div',{staticClass:"text-left v-card-custom-title-left",staticStyle:{"width":"60%"}},[_vm._v(" Locking Position Alert ")]),_c('div',{staticClass:"text-right v-card-custom-title-right",staticStyle:{"width":"40%"}},[_c('download-excel',{staticStyle:{"display":"inline"},attrs:{"data":_vm.filteredAlertData,"fields":_vm.csvHeader,"type":"csv","name":"Locking_Position.csv"}},[_c('v-icon',{attrs:{"color":"green","dark":"","small":"","text":"","disabled":_vm.filteredAlertData.length === 0}},[_vm._v(" mdi-microsoft-excel ")])],1),_vm._v(" "+_vm._s(" ")+" "),(_vm.showConfig)?_c('v-icon',{attrs:{"text":"","small":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.getConfigForAlertAction('Locking Position')}}},[_vm._v("mdi-cog")]):_vm._e(),_vm._v(" "+_vm._s(" ")+" "),_c('v-icon',{staticStyle:{"color":"grey"},attrs:{"small":""},on:{"click":function($event){return _vm.$emit('onRemove')}}},[_vm._v("mdi-close-thick")])],1)]),_c('v-data-table',{staticClass:"elevation-0 alert-datatable",staticStyle:{"max-width":"470px"},attrs:{"dense":"","headers":_vm.alertHeader,"items":_vm.filteredAlertData,"items-per-page":-1,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading-text":"Loading... Please wait","height":_vm.height - 60,"fixed-header":"","header-props":{ sortIcon: null },"hide-default-footer":true,"loading":_vm.loading},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"column":"login"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0",staticStyle:{"background":"transparent","padding":"0px"},attrs:{"color":"default","small":"","dark":""}},'v-btn',attrs,false),on),[_c('a',{on:{"click":function($event){_vm.loadDialog(
                                                _vm.removePrefix(item.user)
                                            )}}},[_vm._v(_vm._s(_vm.extraUserInfo(item.user)))])])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.extractAlertInfo(item))}})]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.new == true),expression:"item.new == true"}]},[_vm._v("mdi-new-box")])],1),_c('td',{attrs:{"column":"symbol"}},[_vm._v(" "+_vm._s(item.symbol)+" ")]),_c('td',{attrs:{"column":"books"}},[_vm._v(" "+_vm._s(item.books)+" ")]),_c('td',{attrs:{"column":"locking_position","align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.lockingVolume.toFixed(2)))+" ")])])]}}])})],1),(_vm.dialog)?_c('LoginSummaryDialog',{attrs:{"login":_vm.selectedLogin,"brokerId":_vm.selctedBrokerId,"brokerName":_vm.selectedBrokerName}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }