<template>
    <div>
        <v-card outlined class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div class="text-left v-card-custom-title-left">Tick Alert</div>
                <div class="text-right v-card-custom-title-right">
                    <v-icon
                        small
                        text
                        color="primary"
                        :loading="loading"
                        v-on:click="refreshData()"
                        >mdi-refresh</v-icon
                    >
                    {{ " " }}
                    <download-excel
                        :data="alertData"
                        :fields="csvHeader"
                        style="display: inline"
                        type="csv"
                        name="Tick.csv"
                    >
                        <v-icon
                            color="green"
                            dark
                            small
                            text
                            :disabled="alertData.length === 0"
                        >
                            mdi-microsoft-excel
                        </v-icon>
                    </download-excel>
                    {{ " " }}
                    <v-icon
                        text
                        small
                        color="blue-grey darken-1"
                        v-on:click="getConfigForAlertAction('Tick')"
                        v-if="showConfig"
                        >mdi-cog</v-icon
                    >
                    {{ " " }}
                    <v-icon small style="color: grey" @click="$emit('onRemove')"
                        >mdi-close-thick</v-icon
                    >
                </div>
            </v-card-title>

            <v-data-table
                style="max-width: 940px"
                :loading="loading"
                dense
                :headers="alertHeader"
                :items="alertData"
                class="elevation-0 alert-datatable"
                :items-per-page="-1"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                loading-text="Loading... Please wait"
                :height="height - 60"
                :header-props="{ sortIcon: null }"
                fixed-header
                :hide-default-footer="true"
            >
                <template v-slot:item="{ item }">
                    <tr @mouseover="updateRow(item)">
                        <td column="server">
                            {{ item.server
                            }}<v-icon v-show="item.new == true"
                                >mdi-new-box</v-icon
                            >
                        </td>
                        <td column="symbol">
                            {{ item.symbol }}
                        </td>
                        <td column="security">
                            {{ item.security }}
                        </td>
                        <td column="threshold" align="right">
                            {{ item.threshold }}s
                        </td>
                        <td column="gap" align="right">{{ item.gap }}s</td>

                        <td column="last_update_time">
                            {{ toMTTimeString(item.last_update_time) }}
                        </td>

                        <td column="trigger_time">
                            {{ toMTTimeString(item.trigger_time) }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <LoginSummaryDialog
            v-if="dialog"
            :login="selectedLogin"
            :brokerId="selctedBrokerId"
            :brokerName="selectedBrokerName"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { helper } from "@components/mixins/helper";
import dayjs from "dayjs";

export default {
    mixins: [helper],
    components: { LoginSummaryDialog },
    props: {
        brokerId: null,
        broker: null,
        modules: null,
        height: 0,
        showing: false,
        showConfig: false,
    },
    data() {
        return {
            visible: false,
            selctedBrokerId: this.brokerId,
            selectedBrokerName: this.broker,
            dialog: false,
            selectedLogin: 0,
            timer: null,
            sortBy: "closed_profit_eod",
            sortDesc: true,
            alertInterval: null,
        };
    },
    computed: {
        ...mapState("Tick", [
            "alertData",
            "alertHeader",
            "loading",
            "csvHeader",
        ]),
        ...mapState("Alert", ["selectedProfile", "selectedPeriod"]),
    },
    watch: {
        selectedProfile(nv) {
            // this.getAlertData();
        },
        showing(nv) {
            if (nv) {
                this.getAlertData();

                if (this.selectedPeriod > 1) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, this.selectedPeriod * 1000);
                }
            } else {
                clearInterval(this.alertInterval);
            }
        },
        selectedPeriod(nv) {
            if (this.showing) {
                clearInterval(this.alertInterval);
                if (nv !== 0) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, nv * 1000);
                }
            }
        },
    },
    methods: {
        ...mapMutations("Tick", ["UPDATE_ALERT_HEADER", "UPDATE_ALERT_DATA"]),
        ...mapActions("Tick", ["getTickAction"]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        /**
         * Open dialog window
         * @param   {[type]}  login  [login description]
         * @return  {[type]}         [return description]
         */
        loadDialog(user) {
            let login = user;
            this.selectedLogin = login;
            this.dialog = false;
            this.$nextTick(() => {
                this.dialog = true;
            });
        },
        refreshData() {
            this.visible = false;
            this.getAlertData();
        },
        getAlertData() {
            this.getTickAction();
        },
        toggleDateFilter() {
            this.visible = true;
        },
        updateRow(row) {
            if (row.new) {
                const index = this.alertData.indexOf(row);
                this.alertData[index].new = false;
                this.UPDATE_ALERT_DATA(this.alertData);
            }
        },
        toMTTimeString(timestamp) {
            const date = dayjs
                .unix(timestamp)
                .tz("UTC")
                .format("YYYY-MM-DD HH:mm:ss");
            return date;
        },
    },
    destroyed() {
        clearInterval(this.alertInterval);
    },
};
</script>
