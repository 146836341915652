<template>
    <v-card outlined>
        <div
            id="largeExposureChart"
            ref="agriEcoDev"
            :style="
                'height:' + (height - 60).toString() + 'px;' + 'width: 100%'
            "
        ></div>
    </v-card>
</template>
<script>
import * as echarts from "echarts";
import { mapState, mapGetters } from "vuex";
import elementResizeDetectorMaker from "element-resize-detector";

export default {
    props: ["selectedBook", "height"],
    data() {
        return {
            actionTimeout: null,
            locked: false,
            chart: Object,
            option: {
                title: {
                    text: "Loading...",
                    left: "center",
                    top: "40%",
                    textStyle: { color: "gray", fontSize: "30px" },
                },
                series: [
                    {
                        type: "treemap",
                        name: this.selectedBook,
                        width: "90%",
                        height: "90%",
                        itemStyle: {
                            gapWidth: 1,
                            borderWidth: 1,
                            borderColorSaturation: 0.5,
                        },
                        data: [],
                        label: {
                            formatter: (value) =>
                                this.formatter(value, "label"),
                        },
                        upperLabel: {
                            show: true,
                            height: 15,
                            color: "#fff",
                            fontWeight: "bold",
                        },
                        visualDimension: 1,
                        levels: [
                            {
                                color: ["#942e38", "#269f3c"],
                                colorMappingBy: "value",
                            },
                        ],
                    },
                ],
            },
        };
    },
    watch: {
        selectedBook(nv) {
            this.locked = false;
            this.option.series[0].name = nv;
            if (nv === undefined) {
                this.option.title.text = "No Data Available";
                this.option.series[0].data = [];
            } else {
                this.option.title.text = "Loading...";
                this.option.series[0].data = [];
            }
            this.processData();
        },
        profileAndMonitor(nv, ov) {
            if (this.locked) {
                return;
            }
            const profileNew = nv.profile;
            const profileOld = ov.profile;
            if (JSON.stringify(profileNew) !== JSON.stringify(profileOld)) {
                this.option.title.text = "Loading...";
                this.option.series[0].data = [];

                this.processData();
            } else {
                let seriesData = [];
                const monitorDataNew = nv.monitor;
                if (monitorDataNew.length !== 0) {
                    this.option.title.text = "";
                    seriesData.push(
                        { name: "long", value: [0, 1], children: [] },
                        { name: "short", value: [0, -1], children: [] }
                    );

                    let positive = {};
                    let negative = {};

                    monitorDataNew.forEach((item) => {
                        const temp = {
                            user:
                                item.user.split(":")[1] +
                                ":" +
                                item.user.split(":")[2],
                            value: Math.abs(item.exposure),
                            isPositive: item.exposure > 0,
                            symbol: item.symbol,
                        };

                        if (temp.isPositive) {
                            if (positive[temp.symbol]) {
                                positive[temp.symbol].push(temp);
                            } else {
                                positive[temp.symbol] = [temp];
                            }
                            // positive.push(temp);
                        } else {
                            if (negative[temp.symbol]) {
                                negative[temp.symbol].push(temp);
                            } else {
                                negative[temp.symbol] = [temp];
                            }
                            // negative.push(temp);
                        }
                    });

                    let positiveOutput = [];
                    let negativeOutput = [];
                    for (const symbol of Object.keys(positive)) {
                        let sortedSymbol = positive[symbol].sort((p1, p2) =>
                            p1.value < p2.value
                                ? 1
                                : p1.value > p2.value
                                ? -1
                                : 0
                        );

                        const output = {
                            name: symbol,
                            value: 0,
                            isPositive: true,
                            top5: [],
                        };
                        for (const item of sortedSymbol) {
                            output.value += item.value;
                            if (output.top5.length < 5) {
                                output.top5.push({
                                    user: item.user,
                                    exp: item.value,
                                });
                            }
                        }
                        seriesData[0].value[0] += output.value;
                        positiveOutput.push(output);
                    }

                    for (const symbol of Object.keys(negative)) {
                        let sortedSymbol = negative[symbol].sort((p1, p2) =>
                            p1.value < p2.value
                                ? 1
                                : p1.value > p2.value
                                ? -1
                                : 0
                        );

                        const output = {
                            name: symbol,
                            value: 0,
                            isPositive: false,
                            symbol: symbol,
                            top5: [],
                        };
                        for (const item of sortedSymbol) {
                            output.value += item.value;
                            if (output.top5.length < 5) {
                                output.top5.push({
                                    user: item.user,
                                    exp: item.value,
                                });
                            }
                        }
                        seriesData[1].value[0] += output.value;
                        negativeOutput.push(output);
                    }
                    seriesData[1].value[0] = seriesData[0].value[0] + 1;

                    seriesData[0].children = positiveOutput;
                    seriesData[1].children = negativeOutput;

                    this.option.series[0].data = seriesData;

                    this.processData();
                } else {
                    this.option.title.text = "No Data Available";
                    this.option.series[0].data = [];

                    this.processData();
                }
            }
        },
    },
    computed: {
        ...mapState("LargeExposure", ["monitorData", "monitorHeader"]),
        ...mapState("Alert", ["selectedProfile"]),
        profileAndMonitor() {
            return {
                profile: { ...this.selectedProfile },
                monitor: [...this.monitorData],
            };
        },
    },
    methods: {
        /**
         * Dealing up coming exposure data, generate x & y data
         * @return  {[type]}  [return description]
         */
        processData() {
            this.chart.clear();
            this.chart.setOption(this.option, true);
            let erd = elementResizeDetectorMaker();
            const self = this;

            erd.listenTo(this.$refs.agriEcoDev, () => {
                self.$nextTick(() => {
                    self.chart.resize({
                        height: this.$refs.agriEcoDev.clientHeight,
                    });
                });
            });
        },

        formatter(params, mode) {
            if (
                params.data.name === this.selectedBook ||
                params.data.name === "short" ||
                params.data.name === "long"
            ) {
                return params.data.name;
            }

            let value = params.data.value;

            if (value >= 1000 && value < 1000000) {
                params.data.value = (value / 1000).toFixed(2) + "K";
            } else if (value >= 1000000 && value < 1000000000) {
                params.data.value = (value / 1000000).toFixed(2) + "M";
            } else if (value >= 1000000000) {
                params.data.value = (value / 1000000000).toFixed(2) + "B";
            }
            let labelExtra = "\nTop 5:\n";
            if (mode === "label") {
                for (const info of params.data.top5) {
                    let expValue = info.exp;
                    if (expValue >= 1000 && expValue < 1000000) {
                        expValue = (expValue / 1000).toFixed(2) + "K";
                    } else if (expValue >= 1000000 && expValue < 1000000000) {
                        expValue = (expValue / 1000000).toFixed(2) + "M";
                    } else if (expValue >= 1000000000) {
                        expValue = (expValue / 1000000000).toFixed(2) + "B";
                    }
                    expValue = (params.data.isPositive ? "" : "-") + expValue;
                    labelExtra += info.user + " (" + expValue + "),\n";
                }
            }
            return (
                params.data.name +
                (mode === "label" ? "\n" : "<br />") +
                "Exp: " +
                (params.data.isPositive ? "" : "-") +
                params.data.value +
                (mode === "label" ? labelExtra : "")
            );
        },
    },
    mounted() {
        this.chart = echarts.init(this.$refs.agriEcoDev);
        this.processData();
        this.chart.on("click", (params) => {
            if (
                params.data?.name === this.selectedBook ||
                params.data?.name === "short" ||
                params.data?.name === "long" ||
                params.nodeData?.dataIndex === 0
            ) {
                this.locked = false;
            }
        });
        this.chart.on("mouseover", (_) => {
            clearTimeout(this.actionTimeout);
            setTimeout(() => {
                this.locked = true;
            }, 1000);
        });
        this.chart.on("globalout", (_) => {
            this.actionTimeout = setTimeout(() => {
                this.locked = false;
            }, 1000);
        });
    },
};
</script>
