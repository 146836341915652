<template>
    <div>
        <v-card outlined v-show="showAlert" class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div
                    class="text-left v-card-custom-title-left"
                    style="width: 60%"
                >
                    Locking Position Alert
                </div>
                <div
                    class="text-right v-card-custom-title-right"
                    style="width: 40%"
                >
                    <download-excel
                        :data="filteredAlertData"
                        :fields="csvHeader"
                        style="display: inline"
                        type="csv"
                        name="Locking_Position.csv"
                    >
                        <v-icon
                            color="green"
                            dark
                            small
                            text
                            :disabled="filteredAlertData.length === 0"
                        >
                            mdi-microsoft-excel
                        </v-icon>
                    </download-excel>
                    {{ " " }}
                    <v-icon
                        text
                        small
                        color="blue-grey darken-1"
                        v-on:click="getConfigForAlertAction('Locking Position')"
                        v-if="showConfig"
                        >mdi-cog</v-icon
                    >
                    {{ " " }}
                    <v-icon small style="color: grey" @click="$emit('onRemove')"
                        >mdi-close-thick</v-icon
                    >
                </div>
            </v-card-title>

            <v-data-table
                style="max-width: 470px"
                dense
                :headers="alertHeader"
                :items="filteredAlertData"
                class="elevation-0 alert-datatable"
                :items-per-page="-1"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                loading-text="Loading... Please wait"
                :height="height - 60"
                fixed-header
                :header-props="{ sortIcon: null }"
                :hide-default-footer="true"
                :loading="loading"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td column="login">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="default"
                                        class="elevation-0"
                                        small
                                        dark
                                        style="
                                            background: transparent;
                                            padding: 0px;
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <a
                                            v-on:click="
                                                loadDialog(
                                                    removePrefix(item.user)
                                                )
                                            "
                                            >{{ extraUserInfo(item.user) }}</a
                                        >
                                    </v-btn>
                                </template>
                                <span
                                    v-html="extractAlertInfo(item)"
                                ></span> </v-tooltip
                            ><v-icon v-show="item.new == true"
                                >mdi-new-box</v-icon
                            >
                        </td>
                        <td column="symbol">
                            {{ item.symbol }}
                        </td>
                        <td column="books">
                            {{ item.books }}
                        </td>
                        <td column="locking_position" align="right">
                            {{
                                numberWithCommas(item.lockingVolume.toFixed(2))
                            }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <LoginSummaryDialog
            v-if="dialog"
            :login="selectedLogin"
            :brokerId="selctedBrokerId"
            :brokerName="selectedBrokerName"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { helper } from "@components/mixins/helper";
import common from "@assets/js/common";
import deepClone from "deep-clone";

export default {
    mixins: [helper],
    components: { LoginSummaryDialog },
    props: {
        brokerId: null,
        broker: null,
        modules: null,
        height: 0,
        showing: false,
        showConfig: false,
    },
    data() {
        return {
            visible: false,
            showAlert: true,
            showMonitor: false,
            selctedBrokerId: this.brokerId,
            selectedBrokerName: this.broker,
            dialog: false,
            selectedLogin: 0,
            timer: null,
            sortBy: "time",
            sortDesc: true,
            recordDate: common.getMT4TimeString().split(" ")[0],
            alertInterval: null,
        };
    },
    computed: {
        ...mapState("LockingPosition", [
            "alertHeader",
            "alertData",
            "loading",
            "csvHeader",
        ]),
        ...mapState("Alert", ["selectedProfile", "selectedPeriod"]),
        filteredAlertData() {
            if (!!this.selectedProfile.unselectedBooks) {
                if (this.selectedProfile.unselectedBooks.length === 0) {
                    return this.alertData;
                } else {
                    let filtered = [];
                    this.alertData.map((data) => {
                        if (
                            !(
                                data.books.split(",").length == 1 &&
                                this.selectedProfile.unselectedBooks.includes(
                                    data.books
                                )
                            )
                        ) {
                            filtered.push(data);
                        }
                    });
                    return filtered;
                }
            }
            return this.alertData;
        },
    },
    watch: {
        selectedProfile(nv) {
            const settings = nv.settings["lockingPosition"];
            const columnName = Object.keys(settings);
            let newHeader = deepClone(this.alertHeader);
            newHeader.map((item) => {
                if (columnName.includes(item.value)) {
                    item.align = settings[item.value].showing
                        ? ["locking_position"].includes(item.value)
                            ? "right"
                            : "left"
                        : " d-none";
                }
            });
            this.UPDATE_ALERT_HEADER(newHeader);
        },
    },
    methods: {
        ...mapMutations("LockingPosition", [
            "UPDATE_ALERT_HEADER",
            "UPDATE_ALERT_DATA",
            "UPDATE_SELECTED_DATE",
        ]),
        ...mapActions("LockingPosition", ["getDataLockingPositionAction"]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        /**
         * Get user info
         * @param {*} user
         */
        extraUserInfo(user) {
            return user.split(":")[1] + ":" + user.split(":")[2];
        },
        /**
         * Extract fast trade info
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        extractAlertInfo(data) {
            let result = "";

            // result +=
            //     "<p class='mb-2'>Server Name: " + data.server_name + "</p>";
            result +=
                "<p class='mb-2'>Symbol: " + data.symbol.toString() + "</p>";
            result +=
                "<p class='mb-2'>Locking Position: " +
                data.lockingVolume.toString() +
                "</p>";
            result +=
                "<p class='mb-2'>Locking Position Threshold: " +
                data.lockingVolumeThreshold.toString() +
                "</p>";

            return result;
        },
        /**
         * Extra user infomation
         * @param {*} user
         */
        extraUserInfo(user) {
            return user.split(":")[1] + ":" + user.split(":")[2];
        },
        /**
         * Remove string prefix
         *
         * @param   {[type]}  name  [name description]
         * @return  {[type]}        [return description]
         */

        removePrefix(name) {
            return name.split(":")[2];
        },
        /**
         * Open dialog window
         * @param   {[type]}  login  [login description]
         * @return  {[type]}         [return description]
         */
        loadDialog(login) {
            this.selectedLogin = login;
            this.dialog = false;
            this.$nextTick(() => {
                this.dialog = true;
            });
        },
    },
    destroyed() {
        clearInterval(this.alertInterval);
    },
};
</script>
