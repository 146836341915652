<template>
    <div>
        <v-card outlined v-show="showMonitor" class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div
                    class="text-left v-card-custom-title-left"
                    style="width: 65%"
                >
                    Large Exposure Volume Monitor
                </div>
                <div
                    class="text-right v-card-custom-title-right"
                    style="width: 35%"
                >
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                x-small
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ selectedBook }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(item, index) in bookOption"
                                :key="index"
                                dense
                                @click="selectedBook = item"
                            >
                                <v-list-item-title>{{
                                    item
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    {{ " " }}
                    <download-excel
                        :data="filteredMonitorData"
                        :fields="csvHeader"
                        style="display: inline"
                        type="csv"
                        name="Large_Exposure_Volume.csv"
                    >
                        <v-icon
                            color="green"
                            dark
                            small
                            text
                            :disabled="filteredMonitorData.length === 0"
                        >
                            mdi-microsoft-excel
                        </v-icon>
                    </download-excel>
                    {{ " " }}
                    <v-icon
                        text
                        small
                        color="blue-grey darken-1"
                        v-on:click="
                            getConfigForAlertAction('Large Exposure Volume')
                        "
                        v-if="showConfig"
                        >mdi-cog</v-icon
                    >

                    {{ " " }}
                    <v-icon
                        small
                        style="top: 2px; color: grey"
                        @click="$emit('onRemove')"
                        >mdi-close-thick</v-icon
                    >
                </div>
            </v-card-title>

            <v-data-table
            style=" max-width: 510px;"
                dense
                :headers="monitorHeader"
                :items="filteredMonitorData"
                class="elevation-0 alert-datatable"
                :items-per-page="-1"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                loading-text="Loading... Please wait"
                :height="height - 60"
                fixed-header
                :header-props="{ sortIcon: null }"
                :hide-default-footer="true"
            >
                <template v-slot:item.login="{ item }">
                    <v-btn
                        color="default"
                        class="elevation-0"
                        small
                        dark
                        style="background: transparent; padding: 0px"
                    >
                        <a v-on:click="loadDialog(item.login)">{{
                            item.login
                        }}</a>
                    </v-btn>
                </template>

                <template v-slot:item.duration="{ item }">
                    {{ item.close_time_integer - item.open_time_integer }} s
                </template>

                <template v-slot:item.books="{ item }">
                    {{ item.books.join(", ") }}
                </template>
                <template v-slot:item.exposureVolume="{ item }">
                    {{ item.exposureVolume.toFixed(2) }}
                </template>
            </v-data-table>
        </v-card>

        <LoginSummaryDialog
            v-if="dialog"
            :login="selectedLogin"
            :brokerId="selectedBrokerId"
            :brokerName="selectedBrokerName"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { helper } from "@components/mixins/helper";
import ChartLargeExposure from "./chart/ComponentLargeExposureChart";
import common from "@assets/js/common";
import deepClone from "deep-clone";

export default {
    mixins: [helper],
    components: { LoginSummaryDialog, ChartLargeExposure },
    props: {
        brokerId: null,
        broker: null,
        height: 0,
        showConfig: false,
    },
    data() {
        return {
            visible: false,
            showAlert: false,
            showMonitor: true,
            selectedBrokerId: this.brokerId,
            selectedBrokerName: this.broker,
            dialog: false,
            selectedLogin: 0,
            timer: null,
            sortBy: "exposureVolume",
            sortDesc: true,
            bookOption: [],
            recordDate: common.getMT4TimeString().split(" ")[0],
            selectedBook: "B",
        };
    },
    computed: {
        ...mapState("LargeExposureVolume", [
            "alertHeader",
            "alertData",
            "monitorData",
            "monitorHeader",
            "loading",
            "csvHeader",
        ]),
        ...mapState("Alert", ["books", "selectedProfile"]),
        filteredMonitorData() {
            return this.monitorData.filter(
                (item) => item.books[0] === this.selectedBook
            );
        },
    },
    watch: {
        selectedProfile(nv) {
            if (this.books.length !== 0) {
                // if (nv) {
                //     this.UPDATE_MONITOR_DATA([]);
                // }
                const settings = nv.settings["largeExposureVolume"];
                const columnName = Object.keys(settings);
                let newHeader = deepClone(this.monitorHeader);
                newHeader.map((item) => {
                    if (columnName.includes(item.value)) {
                        item.align = settings[item.value].showing
                            ? ["exposureVolume"].includes(item.value)
                                ? "right"
                                : "left"
                            : " d-none";
                    }
                });
                let filtered = [];
                this.books.map((book) => {
                    if (!nv.unselectedBooks.includes(book)) filtered.push(book);
                });
                this.bookOption = filtered;
                this.UPDATE_MONITOR_HEADER(newHeader);
            }
        },
        books(nv) {
            if (this.selectedProfile.id !== 0) {
                // if (nv) {
                //     this.UPDATE_MONITOR_DATA([]);
                // }
                const settings =
                    this.selectedProfile.settings["largeExposureVolume"];
                const columnName = Object.keys(settings);
                let newHeader = deepClone(this.monitorHeader);
                newHeader.map((item) => {
                    if (columnName.includes(item.value)) {
                        item.align = settings[item.value].showing
                            ? ["exposureVolume"].includes(item.value)
                                ? "right"
                                : "left"
                            : " d-none";
                    }
                });
                let filtered = [];
                this.books.map((book) => {
                    if (!this.selectedProfile.unselectedBooks.includes(book))
                        filtered.push(book);
                });
                this.bookOption = filtered;
                this.UPDATE_MONITOR_HEADER(newHeader);
            }
        },
        bookOption(nv) {
            if (nv.includes("B")) {
                this.selectedBook = "B";
            } else {
                this.selectedBook = nv[0];
            }
        },
    },
    methods: {
        ...mapMutations("LargeExposureVolume", ["UPDATE_MONITOR_HEADER"]),
        ...mapMutations("LargeExposureVolume", ["UPDATE_MONITOR_DATA"]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        /**
         * Remove string prefix
         *
         * @param   {[type]}  name  [name description]
         * @return  {[type]}        [return description]
         */
        removePrefix(name) {
            return name.split(":")[1];
        },
        /**
         * Open dialog window
         * @param   {[type]}  login  [login description]
         * @return  {[type]}         [return description]
         */
        loadDialog(user) {
            let login = user.split(":")[1];
            this.selectedLogin = login;
            this.dialog = false;
            this.$nextTick(() => {
                this.dialog = true;
            });
        },
    },
};
</script>

<style scoped>
.v-list-item--dense,
.v-list--dense .v-list-item {
    min-height: 30px;
}
</style>
