<template>
    <div>
        <v-card outlined v-show="showAlert" class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div class="text-left v-card-custom-title-left">
                    Volume Alert
                </div>
                <div class="text-right v-card-custom-title-right">
                    <v-icon
                        small
                        text
                        color="primary"
                        :loading="loading"
                        v-on:click="refreshData()"
                        >mdi-refresh</v-icon
                    >
                    {{ " " }}
                    <v-icon
                        small
                        text
                        color="#d35400"
                        :loading="loading"
                        v-on:click="toggleDateFilter()"
                        >mdi-calendar</v-icon
                    >
                    {{ " " }}
                    <download-excel
                        :data="filteredAlertData"
                        :fields="csvHeader"
                        style="display: inline"
                        type="csv"
                        name="Weighted_Volume.csv"
                    >
                        <v-icon
                            color="green"
                            dark
                            small
                            text
                            :disabled="filteredAlertData.length === 0"
                        >
                            mdi-microsoft-excel
                        </v-icon>
                    </download-excel>
                    {{ " " }}
                    <v-icon
                        text
                        small
                        color="blue-grey darken-1"
                        v-on:click="getConfigForAlertAction('Weighted Volume')"
                        v-if="showConfig"
                        >mdi-cog</v-icon
                    >
                    {{ " " }}
                    <v-icon small style="color: grey" @click="$emit('onRemove')"
                        >mdi-close-thick</v-icon
                    >
                </div>
            </v-card-title>

            <v-data-table
                style="max-width: 650px"
                dense
                :headers="alertHeader"
                :items="filteredAlertData"
                class="elevation-0 alert-datatable"
                :items-per-page="-1"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                loading-text="Loading... Please wait"
                :height="height - 60"
                fixed-header
                :header-props="{ sortIcon: null }"
                :hide-default-footer="true"
                :loading="loading"
            >
                <template v-slot:item="{ item }">
                    <tr @mouseover="updateRow(item)">
                        <td column="login">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="default"
                                        class="elevation-0"
                                        small
                                        dark
                                        style="
                                            background: transparent;
                                            padding: 0px;
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <a
                                            v-on:click="loadDialog(item.login)"
                                            >{{
                                                item.server_name +
                                                ":" +
                                                item.login
                                            }}</a
                                        > </v-btn
                                    ><v-icon v-show="item.new == true"
                                        >mdi-new-box</v-icon
                                    >
                                </template>
                                <span v-html="extractAlertInfo(item)"></span>
                            </v-tooltip>
                        </td>
                        <td
                            column="books"
                            v-if="
                                selectedProfile.settings['weightedVolume'].books
                                    .showing
                            "
                        >
                            {{ item.books }}
                        </td>
                        <td
                            column="weighted_volume"
                            align="right"
                            v-if="
                                selectedProfile.settings['weightedVolume']
                                    .weighted_volume.showing
                            "
                        >
                            {{
                                numberWithCommas(
                                    item.weighted_volume.toFixed(2)
                                )
                            }}
                        </td>
                        <td
                            column="closed_profit_total"
                            align="right"
                            v-if="
                                selectedProfile.settings['weightedVolume']
                                    .closed_profit_total.showing
                            "
                        >
                            {{
                                numberWithCommas(
                                    item.closed_profit_total.toFixed(2)
                                )
                            }}
                        </td>
                        <td
                            column="closed_profit_total_eod"
                            align="right"
                            v-if="
                                selectedProfile.settings['weightedVolume']
                                    .closed_profit_total_eod.showing
                            "
                        >
                            {{
                                numberWithCommas(
                                    item.closed_profit_total_eod.toFixed(2)
                                )
                            }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="visible" width="400" :hide-overlay="false">
            <v-card>
                <v-card-title class="text-h5">Filter Date</v-card-title>
                <v-card-text
                    >Choose date to filter history alerts data.</v-card-text
                >
                <v-container>
                    <v-row>
                        <v-col
                            lg="12"
                            md="12"
                            xs="12"
                            cols="12"
                            style="text-align: center"
                        >
                            <v-date-picker v-model="recordDate"></v-date-picker>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text v-on:click="refreshData()"
                        >Ok</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoginSummaryDialog
            v-if="dialog"
            :login="selectedLogin"
            :brokerId="selctedBrokerId"
            :brokerName="selectedBrokerName"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { helper } from "@components/mixins/helper";
import common from "@assets/js/common";
import deepClone from "deep-clone";

export default {
    mixins: [helper],
    components: { LoginSummaryDialog },
    props: {
        brokerId: null,
        broker: null,
        modules: null,
        height: 0,
        showing: false,
        showConfig: false,
    },
    data() {
        return {
            visible: false,
            showAlert: true,
            showMonitor: true,
            selctedBrokerId: this.brokerId,
            selectedBrokerName: this.broker,
            dialog: false,
            selectedLogin: 0,
            timer: null,
            sortBy: "weighted_volume",
            sortDesc: true,
            recordDate: common.getMT4TimeString().split(" ")[0],
            alertInterval: null,
        };
    },
    computed: {
        ...mapState("WeightedVolume", [
            "alertHeader",
            "alertData",
            "monitorData",
            "monitorHeader",
            "loading",
            "csvHeader",
        ]),
        ...mapState("Alert", ["selectedProfile", "selectedPeriod"]),
        filteredAlertData() {
            if (!!this.selectedProfile.unselectedBooks) {
                if (this.selectedProfile.unselectedBooks.length === 0) {
                    return this.alertData;
                } else {
                    let filtered = [];
                    this.alertData.map((data) => {
                        if (
                            !(
                                data.books.split(",").length == 1 &&
                                this.selectedProfile.unselectedBooks.includes(
                                    data.books
                                )
                            )
                        ) {
                            filtered.push(data);
                        }
                    });
                    return filtered;
                }
            }
            return this.alertData;
        },
    },
    watch: {
        selectedProfile(nv) {
            const settings = nv.settings["weightedVolume"];
            const columnName = Object.keys(settings);
            let newHeader = deepClone(this.alertHeader);
            newHeader.map((item) => {
                if (columnName.includes(item.value)) {
                    item.align = settings[item.value].showing
                        ? [
                              "weighted_volume",
                              "closed_profit_eod",
                              "closed_profit",
                          ].includes(item.value)
                            ? "right"
                            : "left"
                        : " d-none";
                }
            });
            this.UPDATE_ALERT_HEADER(newHeader);
        },
        showing(nv) {
            if (nv) {
                this.getAlertData();

                if (this.selectedPeriod > 1) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, this.selectedPeriod * 1000);
                }
            } else {
                clearInterval(this.alertInterval);
            }
        },
        selectedPeriod(nv) {
            if (this.showing) {
                clearInterval(this.alertInterval);
                if (nv !== 0) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, nv * 1000);
                }
            }
        },
    },
    methods: {
        ...mapMutations("WeightedVolume", [
            "UPDATE_ALERT_HEADER",
            "UPDATE_ALERT_DATA",
            "UPDATE_SELECTED_DATE",
        ]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        ...mapActions("WeightedVolume", ["getDataWeightedVolumeAction"]),
        /**
         * Extract fast trade info
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        extractAlertInfo(data) {
            let result = "";

            result +=
                "<p class='mb-2'>Server Name: " + data.server_name + "</p>";
            result +=
                "<p class='mb-2'>Weighted Volume: " +
                data.weighted_volume.toString() +
                "</p>";
            result +=
                "<p class='mb-2'>Volume Threshold: " +
                data.weighted_volume_threshold.toString() +
                "</p>";
            result +=
                "<p class='mb-2'>Trigger Time: " + data.trigger_time + "</p>";

            return result;
        },
        /**
         * Open date picker modal
         * @return  {[type]}  [return description]
         */
        toggleDateFilter() {
            this.visible = true;
        },
        /**
         * Refresh alert data
         * @return  {[type]}  [return description]
         */
        refreshData() {
            this.visible = false;
            this.getAlertData();
        },
        /**
         * Get alet data
         * @return  {[type]}  [return description]
         */
        getAlertData() {
            this.UPDATE_SELECTED_DATE(this.recordDate);

            let params = {
                record_date: this.recordDate,
                // start_time: this.recordDate + " 00:00:00",
                // end_time: this.recordDate + " 23:59:59"
            };
            this.getDataWeightedVolumeAction(params);
        },
        /**
         * Change tabs
         * @param   {[type]}  loadModules  [loadModules description]
         * @return  {[type]}               [return description]
         */
        changeTab(loadModules) {
            this.showAlert = false;
            this.showMonitor = false;
            if (loadModules == 1) this.showMonitor = true;
            if (loadModules == 2) this.showAlert = true;
        },
        /**
         * Remove string prefix
         *
         * @param   {[type]}  name  [name description]
         * @return  {[type]}        [return description]
         */
        removePrefix(name) {
            return name.split(":")[1];
        },
        /**
         * Open dialog window
         * @param   {[type]}  login  [login description]
         * @return  {[type]}         [return description]
         */
        loadDialog(login) {
            this.selectedLogin = login;
            this.dialog = false;
            this.$nextTick(() => {
                this.dialog = true;
            });
        },
        updateRow(row) {
            if (row.new) {
                const index = this.alertData.indexOf(row);
                this.alertData[index].new = false;
                this.UPDATE_ALERT_DATA(this.alertData);
            }
        },
    },
    destroyed() {
        clearInterval(this.alertInterval);
    },
};
</script>
